import { Footer } from "@components/site/Footer"
import { Header } from "@components/site/Header"
import { ScrollRestoration } from "eddev/routing"
import { defineView } from "eddev/views"

export default defineView("_app", (props) => {
  return (
    <>
      <ScrollRestoration />
      {/* <Header /> */}
      <main className="min-h-selectMinH">{props.children}</main>
      {/* <Footer /> */}
    </>
  )
})
